import React from "react";
import namastelogo from "../assets/namastelogo.png";
import Cars from "../assets/Cars.jpg";
import carOne from "../assets/carOne.jpg";
import carTwo from "../assets/carTwo.jpg";
import carThree from "../assets/carThree.jpg";
import carFour from "../assets/carFour.jpg";
import carFive from "../assets/carFive.jpg";
import carSix from "../assets/carSix.jpg";
import carSeven from "../assets/carSeven.jpg";
import carEight from "../assets/carEight.jpg";
import carNine from "../assets/carNine.jpg";
import carTen from "../assets/carTen.jpg";
import carEleven from "../assets/carEleven.jpg";
import namasteCar3 from "../assets/namasteCar3.jpg";
import namasteCar4 from "../assets/namasteCar4.jpg";

import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import namasteCar1 from "../assets/namasteCar1.jpg";
import namasteCar2 from "../assets/namasteCar2.jpg";
import { staggerContainer, textVariant, fadeIn } from "../utils/motion";

const Home = () => {
  const navigate = useNavigate();
  const handleBookClick = () => {
    navigate("/book"); // Navigate to the '/book' route
  };

  const handleContactClick = () => {
    navigate("/contact"); // Navigate to the '/contact' route
  };
  return (
    <>
      <div className="pt-24 mt-12">
        <motion.div
          variants={staggerContainer}
          initial="hidden"
          animate="show"
          className="container px-3 mx-auto flex flex-wrap flex-col lg:flex-row items-center"
        >
          <motion.div
            variants={fadeIn("right", "tween", 0.2, 1)}
            className="flex flex-col w-full md:w-2/5 justify-center items-center lg:items-start text-center md:text-left"
          >
            <p className="uppercase tracking-loose w-full text-black">
              Reach your Medical Destination with Us!
            </p>
            <h1 className="my-4 text-3xl font-bold leading-tight text-indigo-500">
              Namaste Transporation LLC
            </h1>
            <p className="leading-normal text-md mb-8 uppercase text-black">
              We provide safe, comfortable, and reliable non-emergency medical
              transportation.
            </p>
            <div className="flex gap-4">
              <button
                onClick={handleContactClick}
                className="mx-auto gradient lg:mx-0 hover:underline bg-white text-gray-800 hover:text-indigo-500 font-bold border-[white] rounded-full my-6 py-4 px-8 shadow-lg transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
              >
                Contact Us
              </button>

              <button
                onClick={handleBookClick}
                className="mx-auto gradient lg:mx-0 hover:underline bg-white text-gray-800 hover:text-indigo-500 font-bold border-[white] rounded-full my-6 py-4 px-8 shadow-lg transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300"
              >
                Book a Ride
              </button>
            </div>
          </motion.div>
          <motion.div
            variants={textVariant(1.1)}
            className="flex flex-col lg:ml-[8rem] w-full md:w-2/5 justify-end items-end text-end md:text-left"
          >
            <img
              className="rounded-xl shadow-2xl"
              alt="heroSectionPic"
              src={carFive}
            />
          </motion.div>
        </motion.div>
      </div>
      <div classNameName="relative -mt-12 lg:-mt-24">
        <svg
          viewBox="0 0 1428 174"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xlinkHref="http://www.w3.org/1999/xlink"
        >
          <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
            <g
              transform="translate(-2.000000, 44.000000)"
              fill="#FFFFFF"
              fill-rule="nonzero"
            >
              <path
                d="M0,0 C90.7283404,0.927527913 147.912752,27.187927 291.910178,59.9119003 C387.908462,81.7278826 543.605069,89.334785 759,82.7326078 C469.336065,156.254352 216.336065,153.6679 0,74.9732496"
                opacity="0.100000001"
              ></path>
              <path
                d="M100,104.708498 C277.413333,72.2345949 426.147877,52.5246657 546.203633,45.5787101 C666.259389,38.6327546 810.524845,41.7979068 979,55.0741668 C931.069965,56.122511 810.303266,74.8455141 616.699903,111.243176 C423.096539,147.640838 250.863238,145.462612 100,104.708498 Z"
                opacity="0.100000001"
              ></path>
              <path
                d="M1046,51.6521276 C1130.83045,29.328812 1279.08318,17.607883 1439,40.1656806 L1439,120 C1271.17211,77.9435312 1140.17211,55.1609071 1046,51.6521276 Z"
                id="Path-4"
                opacity="0.200000003"
              ></path>
            </g>
            <g
              transform="translate(-4.000000, 76.000000)"
              fill="#FFFFFF"
              fill-rule="nonzero"
            >
              <path d="M0.457,34.035 C57.086,53.198 98.208,65.809 123.822,71.865 C181.454,85.495 234.295,90.29 272.033,93.459 C311.355,96.759 396.635,95.801 461.025,91.663 C486.76,90.01 518.727,86.372 556.926,80.752 C595.747,74.596 622.372,70.008 636.799,66.991 C663.913,61.324 712.501,49.503 727.605,46.128 C780.47,34.317 818.839,22.532 856.324,15.904 C922.689,4.169 955.676,2.522 1011.185,0.432 C1060.705,1.477 1097.39,3.129 1121.236,5.387 C1161.703,9.219 1208.621,17.821 1235.4,22.304 C1285.855,30.748 1354.351,47.432 1440.886,72.354 L1441.191,104.352 L1.121,104.031 L0.457,34.035 Z"></path>
            </g>
          </g>
        </svg>
      </div>
      <section className="bg-white text-black">
        <div className="max-w-auto flex flex-col items-center justify-center  px-4 py-8 sm:px-6 sm:py-12 lg:px-8 lg:py-16">
          <div className="max-w-xl">
            <h2 className="text-3xl font-bold sm:text-4xl text-indigo-500">
              What makes us special?
            </h2>
          </div>

          <div className="mt-8 grid grid-cols-1 gap-8 md:mt-16 md:grid-cols-2 md:gap-12 lg:grid-cols-3">
            <div className="flex items-start gap-4">
              <div>
                <h2 className="text-lg font-bold">Flexible Services</h2>

                <p className="mt-1 text-sm text-gray-600">
                  We understand that our clients' needs may vary, which is why
                  we offer flexible services tailored to accommodate their
                  specific requirements. Whether it's a one-time transfer,
                  regular commuting, or customized travel itineraries, Namaste
                  Transportation is equipped to meet diverse needs.
                </p>
              </div>
            </div>

            <div className="flex items-start gap-4">
              <div>
                <h2 className="text-lg font-bold">Reliability</h2>

                <p className="mt-1 text-sm text-gray-600">
                  At Namaste Transportation, we take pride in our unwavering
                  reliability. Our clients trust us because we consistently
                  deliver on-time transportation solutions, ensuring they reach
                  their destinations promptly and without hassle.
                </p>
              </div>
            </div>

            <div className="flex items-start gap-4">
              <div>
                <h2 className="text-lg font-bold">Professional Drivers</h2>

                <p className="mt-1 text-sm text-gray-600">
                  Our team consists of highly skilled and professional drivers
                  who prioritize both safety and customer satisfaction. With
                  their courteous demeanor and expert driving abilities, our
                  drivers ensure that every journey with Namaste Transportation
                  is pleasant and secure.
                </p>
              </div>
            </div>

            <div className="flex items-start gap-4">
              <div>
                <h2 className="text-lg font-bold">
                  Exceptional Customer Service
                </h2>

                <p className="mt-1 text-sm text-gray-600">
                  At Namaste Transportation, we go above and beyond to provide
                  exceptional customer service. From assisting with reservations
                  to accommodating special requests, our dedicated customer
                  service team ensures that every client receives personalized
                  attention and support throughout their experience with us.
                </p>
              </div>
            </div>

            <div className="flex items-start gap-4">
              <div>
                <h2 className="text-lg font-bold">
                  Clean and Well-Maintained Vehicles
                </h2>

                <p className="mt-1 text-sm text-gray-600">
                  Hygiene and vehicle maintenance are paramount at Namaste
                  Transportation. We meticulously clean and maintain our fleet
                  to the highest standards, ensuring that our clients always
                  travel in pristine and comfortable vehicles.
                </p>
              </div>
            </div>

            <div className="flex items-start gap-4">
              <div>
                <h2 className="text-lg font-bold">Positive Reputation</h2>

                <p className="mt-1 text-sm text-gray-600">
                  Over the years, Namaste Transportation has built a strong
                  reputation for excellence in the transportation industry. Our
                  satisfied clients' testimonials and positive reviews attest to
                  our commitment to quality service and customer satisfaction.
                </p>
              </div>
            </div>
          </div>
          <div className="max-w-xl">
            <p className="mt-12 text-gray-600">
              By considering these factors, Namaste Transportation emerges as an
              appealing choice for individuals or businesses seeking reliable,
              professional, and customer-centric transportation services.
            </p>
          </div>
        </div>
      </section>
      <section>
        <div class="container mx-auto p-4">
          <div class="grid grid-cols-2 md:grid-cols-4 gap-4">
            <div class="grid gap-4">
              <div>
                <img class="h-auto max-w-full rounded-lg" src={carOne} alt="" />
              </div>
              <div>
                <img class="h-auto max-w-full rounded-lg" src={carTwo} alt="" />
              </div>
              <div>
                <img
                  class="h-auto max-w-full rounded-lg"
                  src={carThree}
                  alt=""
                />
              </div>
              <div>
                <img
                  class="h-auto max-w-full rounded-lg"
                  src={namasteCar1}
                  alt=""
                />
              </div>
            </div>
            <div class="grid gap-4">
              <div>
                <img
                  class="h-auto max-w-full rounded-lg"
                  src={carFour}
                  alt=""
                />
              </div>
              <div>
                <img
                  class="h-auto max-w-full rounded-lg"
                  src={carFive}
                  alt=""
                />
              </div>
              <div>
                <img class="h-auto max-w-full rounded-lg" src={carSix} alt="" />
              </div>
            </div>
            <div class="grid gap-4">
              <div>
                <img
                  class="h-auto max-w-full rounded-lg"
                  src={carSeven}
                  alt=""
                />
              </div>
              <div>
                <img
                  class="h-auto max-w-full rounded-lg"
                  src={carEight}
                  alt=""
                />
              </div>
              <div>
                <img
                  class="h-auto max-w-full rounded-lg"
                  src={carNine}
                  alt=""
                />
              </div>
            </div>
            <div class="grid gap-4">
              <div>
                <img class="h-auto max-w-full rounded-lg" src={carTen} alt="" />
              </div>
              <div>
                <img
                  class="h-auto max-w-full rounded-lg"
                  src={carEleven}
                  alt=""
                />
              </div>
              <div>
                <img class="h-auto max-w-full rounded-lg" src={Cars} alt="" />
              </div>
              <div>
                <img
                  class="h-auto max-w-full rounded-lg"
                  src={namasteCar2}
                  alt=""
                />
              </div>
              <div>
                <img
                  class="h-auto max-w-full rounded-lg"
                  src={namasteCar3}
                  alt=""
                />
              </div>
              <div>
                <img
                  class="h-auto max-w-full rounded-lg"
                  src={namasteCar4}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="flex flex-col items-center gap-4 rounded-lg bg-indigo-600 p-6 shadow-lg sm:flex-row sm:justify-between">
        <strong className="text-xl text-white sm:text-xl">
          {" "}
          Book Your Ride With Us!{" "}
        </strong>

        <button
          className="inline-flex items-center gap-2 rounded-full border border-white bg-white px-8 py-3 text-indigo-600 hover:bg-transparent hover:text-white focus:outline-none focus:ring active:bg-white/90"
          onClick={handleBookClick}
        >
          <span className="text-sm font-medium"> Let's Get Started </span>

          <svg
            className="h-5 w-5 rtl:rotate-180"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M17 8l4 4m0 0l-4 4m4-4H3"
            />
          </svg>
        </button>
      </div>
      <section className="bg-gray-50">
        <div className="mx-auto max-w-[1340px] px-4 py-12 sm:px-6 lg:me-0 lg:py-16 lg:pe-0 lg:ps-8 xl:py-24">
          <div className="grid grid-cols-1 gap-8 lg:grid-cols-3 lg:items-center lg:gap-16">
            <div className="max-w-xl text-center ltr:sm:text-left rtl:sm:text-right">
              <div className="hidden lg:mt-8 lg:flex lg:gap-4">
                <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                  Don't just take our word for it...
                </h2>
                <button
                  aria-label="Next slide"
                  id="keen-slider-next-desktop"
                  className="hidden md:block rounded-full border border-[#4338CA] p-3 text-[#4338CA] transition"
                >
                  <svg
                    className="h-5 w-5 rtl:rotate-180"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 5l7 7-7 7"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                    />
                  </svg>
                </button>
              </div>
            </div>

            <div className="-mx-6 lg:col-span-2 lg:mx-0">
              <div id="keen-slider" className="keen-slider">
                <div className="keen-slider__slide">
                  <blockquote className="flex h-full flex-col justify-between bg-white p-6 shadow-sm sm:p-8 lg:p-12">
                    <div>
                      <div className="flex gap-0.5 text-yellow-500">
                        <svg
                          className="h-5 w-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>

                        <svg
                          className="h-5 w-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>

                        <svg
                          className="h-5 w-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>

                        <svg
                          className="h-5 w-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>

                        <svg
                          className="h-5 w-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                      </div>

                      <div className="mt-4">
                        <p className="text-2xl font-bold text-[#4338CA] sm:text-3xl">
                          "Exceptional Service, Punctual and Professional!"
                        </p>

                        <p className="mt-4 leading-relaxed text-gray-700">
                          "Namaste Transportation provides exceptional service!
                          Not only were they consistently punctual, but the
                          professionalism displayed throughout was outstanding.
                          From pick-up to drop-off, everything was smooth and
                          efficient. Highly recommend their excellent service!"
                        </p>
                      </div>
                    </div>

                    <footer className="mt-4 text-sm font-medium text-gray-700 sm:mt-6">
                      &mdash; Kavindra Pulami
                    </footer>
                  </blockquote>
                </div>

                <div className="keen-slider__slide">
                  <blockquote className="flex h-full flex-col justify-between bg-white p-6 shadow-sm sm:p-8 lg:p-12">
                    <div>
                      <div className="flex gap-0.5 text-yellow-500">
                        <svg
                          className="h-5 w-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>

                        <svg
                          className="h-5 w-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>

                        <svg
                          className="h-5 w-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>

                        <svg
                          className="h-5 w-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>

                        <svg
                          className="h-5 w-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                      </div>

                      <div className="mt-4">
                        <p className="text-2xl font-bold text-[#4338CA] sm:text-3xl">
                          "Impeccable Cleanliness, A+ Hygiene Standards!"
                        </p>

                        <p className="mt-4 leading-relaxed text-gray-700">
                          "Impeccable cleanliness! The cars were spotless, and I
                          was truly impressed by the high hygiene standards
                          maintained by Namaste Transportation. Traveling in a
                          clean and sanitized environment was reassuring. Kudos
                          for maintaining such pristine conditions!"
                        </p>
                      </div>
                    </div>

                    <footer className="mt-4 text-sm font-medium text-gray-700 sm:mt-6">
                      &mdash; Olivia Clark
                    </footer>
                  </blockquote>
                </div>

                <div className="keen-slider__slide">
                  <blockquote className="flex h-full flex-col justify-between bg-white p-6 shadow-sm sm:p-8 lg:p-12">
                    <div>
                      <div className="flex gap-0.5 text-yellow-500">
                        <svg
                          className="h-5 w-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>

                        <svg
                          className="h-5 w-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>

                        <svg
                          className="h-5 w-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>

                        <svg
                          className="h-5 w-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>

                        <svg
                          className="h-5 w-5"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                        </svg>
                      </div>

                      <div className="mt-4">
                        <p className="text-2xl font-bold text-[#4338CA] sm:text-3xl">
                          Friendly drivers
                        </p>

                        <p className="mt-4 leading-relaxed text-gray-700">
                          "I had a fantastic experience with Namaste
                          Transportation! The drivers were incredibly friendly
                          and accommodating. Their warmth made every ride
                          pleasant, and they went above and beyond to ensure my
                          comfort. A five-star service for their outstanding
                          friendliness!"
                        </p>
                      </div>
                    </div>

                    <footer className="mt-4 text-sm font-medium text-gray-700 sm:mt-6">
                      &mdash; Michael Johnson
                    </footer>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Home;
