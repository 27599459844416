import React from "react";
import { motion } from "framer-motion";
import Shyam from "../assets/Shyam.jpg";
import Cars from "../assets/Cars.jpg";
import namasteCar1 from "../assets/namasteCar1.jpg";
import namasteCar2 from "../assets/namasteCar2.jpg";
import { staggerContainer, textVariant, fadeIn } from "../utils/motion";

const About = () => {
  return (
    <>
      <div className="relative isolate overflow-hidden bg-gradient px-6 lg:overflow-visible lg:px-0 mt-12">
        <motion.div
          variants={staggerContainer}
          initial="hidden"
          whileInView="show"
          viewport={{ once: true, amount: 0.25 }}
          className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start lg:gap-y-10 mt-8 md:mt-0"
        >
          <motion.div
            variants={fadeIn("left", "tween", 0.2, 1)}
            className="p-12 lg:sticky lg:top-4 lg:col-start-2 lg:row-span-2 lg:row-start-1 lg:overflow-hidden text-gray-900"
          >
            <img
              className="w-[24rem] sm:w-[36rem] max-w-none rounded-xl bg-gray-900 shadow-xl ring-1 ring-gray-400/10 md:w-[57rem] mt-6"
              src={namasteCar1}
              alt=""
            />
          </motion.div>
          <div className="lg:col-span-2 lg:col-start-1 lg:row-start-2 lg:mx-auto lg:grid lg:w-full lg:max-w-7xl lg:grid-cols-2 lg:gap-x-8 lg:px-8">
            <div>
              <div className="max-w-3xl text-base leading-7 text-gray-700 lg:max-w-lg">
                <motion.div
                  variants={fadeIn("up", "tween", 0.2, 1)}
                  className="isolate bg-gradient mt-12 px-6 py-24 sm:py-32 lg:px-8"
                >
                  <div className="mx-auto  text-center">
                    <h2 className="text-3xl font-bold tracking-tight sm:text-4xl text-indigo-500 rounded-xl p-2">
                      About Us
                    </h2>
                    <p className="py-[25px]">
                      The company was founded in 2019 with a mission to offer
                      safe and reliable transportation to clients. Maintaining a
                      mission-oriented approach, Namaste Transportation ensures
                      safe, comfortable, and punctual transportation. Over time,
                      it has acquired numerous standing order clients for
                      dialysis, medical, dental, and counseling appointments,
                      becoming the default provider for many due to the trust
                      built with the company.
                    </p>
                    <p className="mb-4">
                      Our drivers undergo rigorous selection processes,
                      including drug tests, criminal background checks, DORA,
                      Exclusions, and Sex Offenders checks. Additionally, our
                      drivers are fully insured, providing peace of mind to all
                      passengers.
                    </p>
                    <p className="mb-4">
                      <span className="font-bold">Counties We Serve:</span>
                      <br /> Namaste Transportation LLC, established in 2019,
                      provides non-emergency medical transportation to Medicaid
                      members for Medicaid-covered services.
                    </p>
                  </div>
                  <div className="h-1 mx-auto gradient w-1/6 opacity-25 my-4 py-0 rounded-t"></div>
                  <ul className="list-decimal">
                    <li>
                      <p>Adams</p>
                    </li>
                    <li>
                      <p>Arapahoe</p>
                    </li>
                    <li>
                      <p>Boulder</p>
                    </li>
                    <li>
                      <p>Broomfield</p>
                    </li>
                    <li>
                      <p>Denver</p>
                    </li>
                    <li>
                      <p>Jefferson</p>
                    </li>
                    <li>
                      <p>Larimer</p>
                    </li>
                    <li>
                      <p>Weld</p>
                    </li>
                  </ul>
                </motion.div>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
      <div className="flex flex-col items-center gap-4 rounded-lg bg-indigo-600 p-6 shadow-lg sm:flex-row sm:justify-between">
        <strong className="text-xl text-white sm:text-xl">
          {" "}
          Book Your Ride With Us!{" "}
        </strong>

        <a
          className="inline-flex items-center gap-2 rounded-full border border-white bg-white px-8 py-3 text-indigo-600 hover:bg-transparent hover:text-white focus:outline-none focus:ring active:bg-white/90"
          href="/"
        >
          <span className="text-sm font-medium"> Let's Get Started </span>

          <svg
            className="h-5 w-5 rtl:rotate-180"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M17 8l4 4m0 0l-4 4m4-4H3"
            />
          </svg>
        </a>
      </div>
      <div className="bg-[#F8FAFC]  ">
        <section className="container mx-auto text-center py-6 ">
          <div className="w-full ">
            <h1 className="w-full my-2 text-5xl font-bold leading-tight text-center text-indigo-500">
              Meet Our President and Owner
            </h1>
            <div className="h-1 mx-auto gradient w-1/6 opacity-25 my-0 py-0 rounded-t"></div>
          </div>
        </section>
        <div className="mx-auto max-w-7xl lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 items-start gap-x-8 gap-y-16 sm:gap-y-24 lg:mx-0 lg:max-w-none lg:grid-cols-2 shadow-2xl border-t-transparent rounded-tr-none rounded-tl-none rounded-xl">
            {/* <img
              className=" rounded-xl w-[300px] ml-16 mt-16 h-auto shadow-2xl"
              src={Shyam}
              alt="ShyamsPic"
            /> */}
            <div>
              <div className="text-base leading-7 px-6 text-gray-700 lg:max-w-lg">
                <div className="max-w-base text-sm italic ">
                  <p className="mt-6">
                    Sushil Dahal proudly serves as the President and Owner of
                    Namaste Transportation LLC, a distinguished transportation
                    company dedicated to ensuring safe and reliable passage for
                    clients to their medical appointments across various regions
                    in Colorado. With a strong focus on community service,
                    Namaste Transportation caters to the diverse needs of our
                    communities.
                  </p>
                  <p className="mt-8">
                    Bringing a wealth of experience in client relations, Sushil
                    Dahal previously excelled as a Home and Day Care Owner,
                    where he honed his skills in fostering meaningful
                    relationships with clients and their communities.
                  </p>
                  <p className="mt-8">
                    Before immigrating to America in 2014, Sushil Dahal spent a
                    decade as a principal at a private institution, where he
                    played a pivotal role in educational leadership, engaging
                    closely with students and their families. This experience
                    endowed him with a profound understanding of clients'
                    emotions, expectations, urgencies, and challenges, enriching
                    his ability to provide exceptional service.
                  </p>
                  <p className="mt-8">
                    With an unwavering commitment to client satisfaction, Sushil
                    Dahal ensures that Namaste Transportation delivers the
                    highest quality services, fostering enduring relationships
                    with our valued clients.
                  </p>
                  <p className="mt-8">
                    We invite you to experience the difference with Namaste
                    Transportation. Trust us for secure, reliable, and
                    comfortable transportation to your medical appointments. Let
                    us help you reach your medical destination with peace of
                    mind.
                  </p>
                </div>
              </div>

              <dl className="mt-10 grid grid-cols-2 gap-8 border-t border-gray-900/10 pt-10 sm:grid-cols-4"></dl>
            </div>
            <img
              className=" rounded-xl w-[300px] ml-16 mt-16 h-auto shadow-2xl"
              src={Shyam}
              alt="ShyamsPic"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
